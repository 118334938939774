import React, {Fragment, useEffect, useRef, useState} from "react";
import "./categories.scss";
import CategoryService from "./category.service";
import Breadcrumb from "./components/breadcrumb/Breadcrumb";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {toast} from "../../routes";
import LeftToolbarTemplate from "../../common/templates/TableLeft/left-toolbar-template";
import TableAction from "../../common/components/tableAction/TableAction";

const Index = () => {
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [deletUser, setDeleteUser] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            id: {value: "", matchMode: "contains"},
            title: {value: "", matchMode: "contains"},
        },
    });

    const getCategories = async () => {
        setLoading(true);
        const response = await CategoryService.getCategories(lazyParams);
        setItems(response.data);
        setTotalRecords(response.meta.total);
        setLoading(false);
    };

    useEffect(() => {
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams, deletUser]);
    const deleteCategory = async (id) => {
        try {
            await CategoryService.deleteCategory(id);
            getCategories();
            toast.current.show({
                severity: "success",
                summary: "Succes Message",
                detail: "Role is deleted",
                life: 3000,
            });
        } catch (e) {
            toast.current.show({
                severity: "error",
                summary: "Error Message",
                detail: "Category is not deleted",
                life: 3000,
            });
        }
    };

    const ActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link to={`/categories/addedit/${rowData.id}`}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-success mr-2"
                    />
                </Link>

                <Button
                    onClick={() => deleteCategory(rowData.id)}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning"
                />
            </React.Fragment>
        );
    };

    return (
        <Fragment>
            <Breadcrumb items={[{label: "Categories"}]}/>
            <section className="users">
                <div className="datatable-doc-demo">
                    <div className="card">
                        {items && (
                            <>
                                <Toolbar
                                    className="mb-4"
                                    left={<LeftToolbarTemplate name={'category'}/>}
                                />
                                <DataTable
                                    value={items}
                                    lazy
                                    filterDisplay="row"
                                    responsiveLayout="scroll"
                                    dataKey="id"
                                    paginator
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    totalRecords={totalRecords}
                                    onPage={event => setLazyParams(event)}
                                    onSort={event => setLazyParams(event)}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    onFilter={event => setLazyParams(event)}
                                    filters={lazyParams.filters}
                                    loading={loading}
                                >
                                    <Column
                                        field="id"
                                        header="Id"
                                        sortable
                                        filter
                                        showFilterMenu={false}
                                        showClearButton={false}
                                        filterPlaceholder="id"
                                    />

                                    <Column
                                        field="title"
                                        header="Title"
                                        sortable
                                        filter
                                        showFilterMenu={false}
                                        showClearButton={false}
                                        filterPlaceholder="title"
                                    />

                                    <Column
                                        body={data => <TableAction data={data} name={'category'} deleteItem={id => deleteCategory(id)}/>}
                                        setDeleteUser={setDeleteUser}
                                    />
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Index;

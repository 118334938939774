const genders = [
    {
        id: "man",
        title: "Man"
    },
    {
        id: "woman",
        title: "Woman"
    },
    {
        id: "all",
        title: "All"
    }
];


const featureTypes = [
    {
        id:1,
        title:"Personal",
    },
    {
        id:2,
        title:"Group",
    }
];

export default {
    genders,
    featureTypes
}